:root {
  --black: #000;
  --white: #ffffff;
  --gray: #fffdfd;
  --primary: #000000;
  --primary-active: #111111;
  --primary-disabled: #111111;
  --primary-border: rgb(0, 0, 0, 0.25);
  --primary-box-shadow: rgba(0, 0, 0, 0.5);
  --seconday-active: #5a6268;
  --seconday-border: #5a6268;
  --bg-light: #ffffff;
  --danger: #f73d4f;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus {
  outline: var(--primary-border) !important;
}

a {
  color: var(--primary);
}

a {
  text-decoration: none !important;
}

a.active,
a:focus,
a:hover {
  color: var(--primary-active);
}

a:disabled,
button:disabled,
.btn:disabled {
  cursor: not-allowed;
}

/* Buttons */
.btn {
  border-radius: 0;
  color: var(--primary);
  background-color: var(--white);
  border-color: var(--primary-border);
}

.btn:hover,
.btn:focus {
  color: var(--white);
  background-color: var(--primary-active);
  border-color: var(--primary-border);
}

.btn:active {
  color: var(--white) !important;
  background-color: var(--primary-active) !important;
  border-color: var(--primary-border) !important;
}

.btn:focus {
  box-shadow: 0 0 0 0.2rem var(--primary-box-shadow);
}

.btn:disabled {
  background-color: var(--primary-disabled);
  border-color: var(--primary-border);
}

.btn-primary {
  color: var(--white);
  background-color: var(--primary);
}

.btn-light {
  color: var(--primary);
  background-color: var(--white);
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active {
  background-color: var(--primary);
}

.btn-light:disabled:hover,
.btn-light:disabled:focus,
.btn-light:disabled:active,
.btn-light:disabled {
  color: var(--black);
  background-color: var(--gray);
  border-color: var(--primary-border);
}

.btn-link {
  color: var(--primary);
  background: none;
  border: none;
}

.btn-link:hover,
.btn-link:focus {
  color: var(--primary);
  background: none;
  border: none;
}

.btn-link:active {
  color: var(--primary);
  background: none !important;
  border: none !important;
}

.btn-link:disabled {
  background: none;
  border: none;
}

.btn-secondary {
  color: var(--white);
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: var(--white);
  background-color: var(--seconday-active);
  border-color: var(--seconday-border);
}

/* Forms */
legend {
  font-size: 1em;
}
.required:after {
  content: ' *';
  color: var(--primary);
}

.form-control:focus,
.form-control:active {
  box-shadow: 0 0 0 0.2rem var(--primary-box-shadow) !important;
  border-color: var(--primary-active) !important;
}

input.form-control.invalid {
  border-color: var(--primary) !important;
}

input.form-check-input[type='radio'] {
  margin-top: 0.5em;
  font: 12px/1em sans-serif;
}

input.form-check-input[type='radio']:focus {
  outline-color: var(--primary-active);
}

input.form-check-input[type='radio']:checked:before {
  display: inline-block;
  position: absolute;
  color: white;
  width: 1.38em;
  height: 1.38em;
  padding-top: 0.15em;
  font-size: 0.8em;
  background-color: var(--primary);
  border-radius: 50%;
  content: '✓';
  text-align: center;
}

input.form-check-input[type='checkbox'] {
  margin-top: -4px;
  font: 12px/1em sans-serif;
}
input.form-check-input[type='checkbox']:checked:before {
  display: inline-block;
  color: white;
  width: 13px;
  height: 12.5px;
  background-color: var(--primary);
  border-radius: 20%;
  content: '✓';
  text-align: center;
}

/* Helper classes */
.fluid {
  display: flex;
}

.fluid > * {
  margin-right: 1em;
}

.text-primary {
  color: var(--primary) !important;
}

.text-black {
  color: var(--black) !important;
}

.bg-light {
  background-color: var(--bg-light) !important;
}
